import appStrings from '@/app/utility/string.utility';
export default {
    name: 'ProjectMasterDetails',
    props: {
        projectMasterData: Object
    },
    data() {
        return {
            unsubscribe: null,
            showProjectMasterModal: false,
            editMode: false,
            loader: false,
            showAlert: false,
            isSuccess: false,
            responseMsg: '',
            masterPrjCode: null,
            masterPrjName: null,
            masterPrjId: 0
        }
    },
    mounted() {
        this.unsubscribe = this.$store.subscribe((mutation, state) => {
            if (mutation.type === 'shared/setActionName') {
                const actionName = state.shared.actionName;
                if (actionName === 'save' && this.editMode == true) {
                    this.addEditProjectMaster();
                }
                if (actionName === 'update') {
                    this.editMode = true;
                }
                if (actionName === 'upload') {

                    this.eventBus.$emit('commonUpload', { id: this.masterPrjId});
        
                  }
            }
        });
        if (this.projectMasterData !== null) {
            this.masterPrjCode = this.projectMasterData.master_project_code;
            this.masterPrjId = this.projectMasterData.master_project_id;
            this.masterPrjName = this.projectMasterData.master_project_name;
        }
        else {
            this.editMode = true;
        }
    },
    methods: {
        addEditProjectMaster() {
            const payload = {
                master_project_code: this.masterPrjCode,
                master_project_id: this.masterPrjId,
                master_project_name: this.masterPrjName
            };
            this.loader = true;
            this.$store.dispatch('projects/addEditProjectMaster', payload)
                .then(response => {
                    this.loader = false;
                    if (response.status === 200) {
                        this.$emit('updateProjectMasterList');
                        this.showAlert = true;
                        this.isSuccess = true;
                        this.responseMsg = response.data.message;
                    }
                    else {
                        this.showAlert = true;
                        this.isSuccess = false;
                        this.responseMsg = response.response.data.message;
                    }
                })
                .catch(() => {
                    this.loader = false;
                    this.showAlert = true;
                    this.isSuccess = false;
                    this.responseMsg = appStrings.autoFailedMsg;
                });
        }
    },
    beforeDestroy() {
        this.unsubscribe();
    }
}