import commonHelper from '@/app/utility/common.helper.utility';
import ProjectMasterDetails from './projectMasterDetails';
export default {
  name: 'MasterProjects',
  components: {
    ProjectMasterDetails
  },
  props: {
    showCrmSectorModal: Boolean
  },
  watch: {
    currentPage: function() {
      this.getProjectMasterList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getProjectMasterList();
    }
  },
  data() {
    return {
      unsubscribe: null,
      showProjectMasterModal: false,
      loader: false,
      editMode: false,
      showAlert: false,
      currentPage: 1,
      payload: {},
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      masterPrjCode: '',
      masterPrjName: '',
      tab: null,
      projectMasterData: null,
      masterProjectData: [],
      masterProjectFields: [
        {
          key: 'master_project_code'
        },
        {
          key: 'master_project_id',
          class: 'd-none'
        },
        {
          key: 'master_project_name'
        }
      ]
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.hideProjectMasterModal(true, 'ProjectMasterDetails');
          this.projectMasterData = null;
        }
        if (actionName === 'download' && !this.showProjectMasterModal) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'projects/getProjectMasterList',
            'project-master',
            () => (this.loader = false)
          );
        }
      }
    });
    this.getProjectMasterList();
  },
  methods: {
    setChildName(tab) {
      //method for child modal name
      if (tab === 'ProjectMasterDetails') {
        return (this.childName = 'Project Master Details');
      }
    },
    hideProjectMasterModal(flag, component) {
      this.showProjectMasterModal = flag;
      this.tab = component;
      this.setChildName(this.tab);
    },
    getProjectMasterList() {
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        master_project_code: this.masterPrjCode,
        master_project_name: this.masterPrjName
      };
      this.loader = true;
      this.$store
        .dispatch('projects/getProjectMasterList', this.payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.masterProjectData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelected(rowData) {
      this.projectMasterData = rowData;
      this.$emit('prjMasterProjectDetails', this.projectMasterData);
      this.hideProjectMasterModal(true, 'ProjectMasterDetails');
    },
    updateProjectMasterList() {
      this.getProjectMasterList();
    },
    resetSearchFilters() {
      this.masterPrjCode = '';
      this.masterPrjName = '';
      this.getProjectMasterList();
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
